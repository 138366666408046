<template>
  <div>
    <vs-card>
      <vs-row class="gap">
        <vs-col vs-w="10"><h4>Excel File Upload</h4></vs-col>
        <vs-col vs-w="2">
          <vs-button
            radius
            class="gap"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refreshdata"
          ></vs-button
        ></vs-col>
      </vs-row>

      <vs-row class="gap">
        <!-- <vs-col vs-w="1"></vs-col> -->
        <vs-col vs-w="3">
          <v-select
            style="width:100%"
            placeholder="TYPE"
            v-model="type"
            :options="types"
          ></v-select>
        </vs-col>

        <vs-col vs-w="3">
          <vs-input class="inputx" placeholder="MR Number" v-model="mr_number"
        /></vs-col>
        <vs-col
          vs-w="3"
          style="border: 1px solid #cccccc;border-radius: 5px;">
          <input
            style="position:relative;top:8px;"
            class="file"
            type="file"
            id="file"
            ref="uploadBtn"
            v-on:change="handleFileUpload(file)"
            accept=".xlsx, .xls, .csv"
          />
        </vs-col>
        <vs-col vs-w="3">
          <datepicker
            placeholder="Select Date"
            :format="customFormatter"
            v-model="dates"
            :disabled-dates="disabledDates"
          ></datepicker>
        </vs-col>
      </vs-row>
      <vs-row v-show="formatError">
        <vs-col vs-w='7'></vs-col>
        <vs-col vs-w='5'><p style="color:red">Wrong Format !,  Please upload .xls , .xlsx formats only </p></vs-col>
      </vs-row>
      <br />
      <vs-row>
        <vs-col vs-w="12" style="Justify-content:center;display:flex;">
          <vs-button
            v-show="hideSendButton"
            color="success"
            @click="HdfcSettelment()"
            ><vs-icon class="icon_style" icon="check_circle"></vs-icon
            >&nbsp;&nbsp;Upload</vs-button
          >
          <vs-chip v-show="hiddenSuccessMessage" color="success">
            <vs-avatar icon="check_circle" />
            Uploaded
          </vs-chip>
        </vs-col>
      </vs-row>
    </vs-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import datetime from "vuejs-datetimepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    Datepicker,
    "v-select": vSelect,
    DateRangePicker,
    datetime,
  },
  data() {
    return {
      disabledDates: {
        from: new Date(),
      },
      formatError:false,
      hideSendButton: false,
      hiddenSuccessMessage: false,
      formData: null,
      file: "",
      type: "",
      types: ["Terminal", "UPI", "PAY U"],
      mr_number: null,
      Hdfc_Url: null,
      dates: "",
    };
  },
   watch:{
    dates(val){
       console.log("dates",val);
    }
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    handleFileUpload() {
      this.file = this.$refs.uploadBtn.files;
      this.formData = new FormData();
      console.log("this file", this.file);
      // const arrayOfStrings = ['xls', 'xlsx'];
      
      if (this.file[0].name.indexOf('xls') !== -1 || this.file[0].name.indexOf('xlsx') !== -1 || this.file[0].name.indexOf('XLS') !== -1 || this.file[0].name.indexOf('XLSX') !== -1) {
        this.formData.append("file", this.file[0]);
        //preSignedUrlForFileUpload
        if (this.formData !== null || this.formData !== "") {
             this.formatError = false
          this.hideSendButton = true;
          this.hiddenSuccessMessage = false;
          
        }
      }else{
        this.formatError = true
        this.hideSendButton = false;
      }
    },
    refreshdata() {
      const file = document.querySelector(".file");
      file.value = "";
      this.file = "";
      this.type = "";
      this.mr_number = "";
      this.formData = null;
      this.dates = "";
      this.hiddenSuccessMessage = false;
      this.hideSendButton = false;
    },
    HdfcSettelment() {
      let selected_date = moment(this.dates);
      let dateselected = moment(selected_date._i, "DD-MM-YYYY HH:mm:ss").format(
        "YYYYMMDD"
      );
      // console.log("date selected", dateselected);
      let url = `${constants.SERVER_API}preSignedUrlForFileUpload`;

      this.formData.append("document_type", this.type);
      this.formData.append("mr_number", this.mr_number);
      this.formData.append("mr_date", dateselected);
      // console.log("this.mr_number",this.dates);
      if (
        
        this.type !== "" &&
         this.mr_number !== null
      ) {
        console.log("this.mr_number",this.formData)
         console.log("this.type", url)
        axios
          .post(url, this.formData, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log("response", response);
            this.hiddenSuccessMessage = true;
            this.hideSendButton = false;
            if(response.data.status === "success") {
              this.hiddenSuccessMessage = true;
              this.hideSendButton = false;
              this.$vs.notify({
                title: response.data.message,
                color: "success",
              });
            } else if(response.data.status === "error") {
              this.hiddenSuccessMessage = false;
              this.hideSendButton = true;
              this.$vs.notify({
              title: response.data.message,
              color: "danger",
            });
            }
            
          })
          .catch((error) => {
            this.handleError(error);
          });
        
      }else{
        this.$vs.notify({
              title: `Dont not leave empty fields`,
              text:'',
              color: "danger",
            });
      }
    },
  },
};
</script>

<style>
.icon_style {
  font-size: 17px;
  top: 3px;
  position: relative;
}
.con-vs-avatar {
  background: rgb(255 255 255);
}

.vs-avatar--text.notranslate.material-icons.check_circle.material-icons {
  color: rgb(60 197 70);
  font-size: 20px;
}
</style>
